import type { FC } from 'react';
import React, { useContext, Fragment } from 'react';
import { css } from '@compiled/react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import EditIcon from '@atlaskit/icon/core/migration/edit';
import LikeIcon from '@atlaskit/icon/core/migration/thumbs-up--like';
import CommentIcon from '@atlaskit/icon/core/migration/comment';
import EmojiFrequentIcon from '@atlaskit/icon/core/migration/clock--emoji-frequent';
import { Box, xcss, Flex } from '@atlaskit/primitives';

import { TimeAgo } from '@confluence/time-ago';

import { PageCardContext } from '../PageCardContext';

const i18n = defineMessages({
	edited: {
		id: 'page-card.action.you.edited',
		defaultMessage: 'You edited {timeAgo}',
		description:
			"When you last edited the page. 'timeAgo' is of the form `1 hour ago` or a date `September 15, 2020` (in English)",
	},
	visited: {
		id: 'page-card.action.you.visited',
		defaultMessage: 'You visited {timeAgo}',
		description:
			"When you last visited the page. 'timeAgo' is of the form `1 hour ago` or a date `September 15, 2020` (in English)",
	},
	updated: {
		id: 'page-card.action.updated',
		defaultMessage: 'Updated {timeAgo}',
		description:
			"When the page was last modified. 'timeAgo' is of the form `1 hour ago` or a date `September 15, 2020` (in English)",
	},
	created: {
		id: 'page-card.action.created',
		defaultMessage: 'Created {timeAgo}',
		description:
			"When the page was created. 'timeAgo' is of the form `1 hour ago` or a date `September 15, 2020` (in English)",
	},
	createdByYou: {
		id: 'page-card.action.created.by.me',
		defaultMessage: 'You created {timeAgo}',
		description:
			"When the page was created by 'you'. 'You' being the current user. 'timeAgo' is of the form `1 hour ago` or a date `September 15, 2020` (in English)",
	},
	ownedByYou: {
		id: 'page-card.action.owned.by.me',
		defaultMessage: 'You became owner {timeAgo}',
		description:
			"When the page was owned by 'you'. 'You' being the current user. 'timeAgo' is of the form `1 hour ago` or a date `September 15, 2020` (in English)",
	},
	liked: {
		id: 'page-card.action.liked',
		defaultMessage: 'You liked {timeAgo}',
		description:
			"When the page was liked (Thumb up button like facebook). 'timeAgo' is of the form `1 hour ago` or a date `September 15, 2020` (in English)",
	},
	commented: {
		id: 'page-card.action.commented',
		defaultMessage: 'You commented {timeAgo}',
		description:
			"When the page was commented on by the user. 'timeAgo' is of the form `1 hour ago` or a date `September 15, 2020` (in English)",
	},
});

const Icons = {
	edited: EditIcon,
	visited: EmojiFrequentIcon,
	updated: EmojiFrequentIcon,
	created: EditIcon,
	createdByYou: EditIcon,
	ownedByYou: EditIcon,
	liked: LikeIcon,
	commented: CommentIcon,
};

const LastActionContainerStyles = xcss({
	font: 'font.body.UNSAFE_small',
	color: 'color.text.subtle',
});

const lastActionTextBaseStyles = css({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	color: 'var(--secondary-text-color)',
});

const textMarginLeft = css({
	marginLeft: token('space.100', '8px'),
});

const separatorStyles = xcss({
	margin: 'space.050',
});

type LastActionProps = {
	isInlineAction?: boolean;
};

export const LastAction: FC<LastActionProps> = ({ isInlineAction = false }) => {
	const {
		lastAction: { action, when },
	} = useContext(PageCardContext);
	const Icon = action && Icons[action];
	return (
		<Flex alignItems="center" xcss={LastActionContainerStyles}>
			{action && when ? (
				<Fragment>
					{Icon && !isInlineAction && (
						<Icon
							LEGACY_size="small"
							color={token('color.text.subtle')}
							label=""
							testId="last-action-icon"
							spacing="none"
						/>
					)}
					{isInlineAction && <Box xcss={separatorStyles}>•</Box>}
					{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text*/}
					<span css={[lastActionTextBaseStyles, !isInlineAction && textMarginLeft]}>
						<FormattedMessage {...i18n[action]} values={{ timeAgo: <TimeAgo date={when} /> }} />
					</span>
				</Fragment>
			) : null}
		</Flex>
	);
};
